import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import store from "@/state/store.js";
import formatInputDate from "@/helpers/formatInputDate";
import $ from "jquery";
import togglerCorporateStatus from "@/helpers/toggleCorporateStatus.js";
import corporate from "@/helpers/corporate";
export default {
  name: "profile",
  data() {
    return {
      updateinformationState: "initial",
      profileUpdated: false,
      imageUrl: "",
      updateType: "",
      fileError: "",
      errors: [],
      percentage: 0,
      loading: false,
      showUploadedImage: false,
      showProgress: false,
      type: "alert-danger",
      colors: [{
        color: "#F7921C",
        percentage: 0
      }, {
        color: "#F7921C",
        percentage: 20
      }, {
        color: "#F7921C",
        percentage: 40
      }, {
        color: "#F7921C",
        percentage: 60
      }, {
        color: "#F7921C",
        percentage: 80
      }, {
        color: "#F7921C",
        percentage: 100
      }],
      listOfOptions: [{
        value: "Through A friend"
      }, {
        value: "Social Media"
      }, {
        value: "Linkedin"
      }, {
        value: "Google"
      }, {
        value: "Youtube"
      }, {
        value: "Advertisement"
      }, {
        value: "Other"
      }],
      userDataForm: {},
      corporateData: {
        name: "",
        date_of_est: "",
        date_of_birth: "",
        email: "",
        phone: "",
        id: "",
        heard_about_us: "",
        industry_id: "",
        bus_cert: ""
      },
      userProfile: {
        firstname: "",
        lastname: "",
        date_of_birth: "",
        heard_about_us: "",
        industry_id: "",
        phone: "",
        email: ""
      },
      industries: [],
      pageLoading: false,
      userProfileData: false,
      editUserProfile: false,
      editCorporateData: false,
      formData: {
        firstname: "",
        lastname: "",
        name: "",
        heard_about_us: "",
        heard_about_us_other: "",
        date_of_est: "",
        bus_cert: "",
        industry_id: "",
        bus_cert: ""
      },
      currentUser: {},
      rules: {
        firstname: [{
          required: true,
          message: "Please Enter Your First Name.",
          trigger: "change"
        }],
        lastname: [{
          required: true,
          message: "Please Enter Your Last Name.",
          trigger: "change"
        }],
        date_of_birth: [{
          required: false,
          message: "Please this field is required",
          trigger: "change"
        }],
        heard_about_us: [{
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }],
        industry_id: [{
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }],
        email: [{
          required: true,
          message: "Your Email is required",
          trigger: "change"
        }]
      },
      coperateFormRules: {
        name: [{
          required: true,
          message: "Please enter your company's name",
          trigger: "change"
        }],
        date_of_est: [{
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }],
        bus_cert: [{
          required: true,
          message: "Please enter your company's name",
          trigger: "change"
        }],
        heard_about_us: [{
          required: true,
          message: "Please Upload business certificate",
          trigger: "change"
        }],
        industry_id: [{
          required: true,
          message: "Please this field is required",
          trigger: "change"
        }],
        email: [{
          required: true,
          message: "Your Email is required",
          trigger: "change"
        }]
      }
    };
  },
  computed: {
    checkProfileStatus() {},
    userAvatarLetters() {
      let firstLetter,
        secondLetter = "";
      if (this.currentUser.profile_type === 2) {
        firstLetter = this.currentUser.firstname ? this.currentUser.firstname.charAt(0).toUpperCase() : "";
        secondLetter = this.currentUser.lastname ? this.currentUser.lastname.charAt(0).toUpperCase() : "";
      } else {
        firstLetter = this.currentUser.name && this.currentUser.name.split(" ")[0] ? this.currentUser.name.split(" ")[0].charAt(0).toUpperCase() : "";
        secondLetter = this.currentUser.name && this.currentUser.name.split(" ")[1] ? this.currentUser.name.split(" ")[1].charAt(0).toUpperCase() : "";
      }
      return `${firstLetter}${secondLetter}`;
    },
    getUsername() {
      if (!this.currentUser) return "";
      return this.currentUser.name;
    },
    corporate() {
      return corporate();
    }
  },
  mounted() {
    store.dispatch("auth/getUserProfileStatus").then(res => {
      let userData = res.data.data;
      if (userData.profile_type === 2) {
        userData.name = `${userData.firstname} ${userData.lastname}`;
      }
      this.currentUser = userData;
    });
    store.dispatch("industries/getIndustryReporting").then(res => this.industries = res);
    store.dispatch("auth/viewCorporateProfile").then(res => {
      this.profileUpdated = true;
      this.corporateData = {
        ...res.data.data
      };
    }).catch(() => {});
    this.pageLoading = true;
    store.dispatch("auth/viewIndividualProfile").then(response => {
      //this.clearStorage()
      this.pageLoading = true;
      this.profileUpdated = true;
      if (response.data.data) {
        this.userProfileData = true;
        this.userProfile = {
          ...response.data.data
        };
      }
    }).finally(() => this.pageLoading = false);
  },
  methods: {
    back() {
      this.updateinformationState = "initial";
      this.loading = false;
      this.errors = [];
      this.formData = {
        firstname: "",
        lastname: "",
        day: "",
        month: "",
        year: "",
        name: "",
        heard_about_us: "",
        date_of_est: "",
        bus_cert: "",
        industry_id: "",
        bus_cert: ""
      };
      this.corporateData = {
        firstname: "",
        lastname: "",
        day: "",
        month: "",
        year: "",
        name: "",
        heard_about_us: "",
        date_of_est: "",
        bus_cert: "",
        industry_id: "",
        bus_cert: ""
      };
    },
    url() {
      return process.env.VUE_APP_ENGINE_URL;
    },
    showEditCorporateProfile() {
      this.formData = {
        ...this.corporateData
      };
      this.imageUrl = `${process.env.VUE_APP_ENGINE_URL}/storage/bus_certs/${this.corporateData.bus_cert}`;
      this.editCorporateData = true;
      this.updateinformationState = "cooperate";
      this.updateType = "Coporate account";
    },
    showEditIndividualProfile() {
      this.formData = {
        ...this.userProfile
      };
      // Current implementation is to catch the default value and set it to null so that the datepicker can be empty
      if (this.formData.date_of_birth == "0000-00-00") {
        this.formData.date_of_birth = "";
      }
      this.editUserProfile = true;
      this.individualBtn();
    },
    removeUploadedImage(e) {
      this.showUploadedImage = false;
      this.formData.bus_cert = "";
      this.showProgress = false;
      this.imageUrl = "";
      e.preventDefault();
    },
    handleFileChange(event) {
      let file = this.$refs.certificate ? this.$refs.certificate.files : "";
      if (Object.keys(file).length !== 0) {
        let actualFile = file[0];
        this.formData.bus_cert = actualFile;
        this.showProgress = true;
        this.imageUrl = URL.createObjectURL(actualFile);
      }
    },
    handleUploadFile() {
      this.handleFileChange();
      document.getElementById("fileUpload").click();
      this.imageUrl = "";
    },
    individualBtn() {
      this.updateinformationState = "individual";
      this.updateType = "Personal account";
    },
    cooperateBtn(data = "Coporate account") {
      this.updateinformationState = "cooperate";
      this.updateType = data;
    },
    closeModalBtn() {
      this.updateType = "";
      this.updateinformationState = "initial";
      this.editUserProfile = false;
      $("#exampleModal").modal("hide");
    },
    resetModal() {},
    showModal() {},
    editCoporateData(formName) {
      this.errors = [];
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          let {
            date_of_est,
            heard_about_us,
            name,
            bus_cert,
            industry_id
          } = this.formData;
          let date = formatInputDate(date_of_est);
          //CREATE FORM DATA
          // if(Object.keys(bus_cert).length == 0) {
          //     this.fileError = "Please upload business certificate."
          // }
          let formData = new FormData();
          formData.append("name", name);
          formData.append("date_of_est", date);
          formData.append("bus_cert", bus_cert);
          formData.append("heard_about_us", heard_about_us);
          formData.append("industry_id", industry_id);
          // return;
          store.dispatch("auth/editCoporateProfile", formData).then(response => {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success"
            });
            togglerCorporateStatus(true);
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }).catch(err => {
            var _err$response;
            this.loading = false;
            if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
              this.errors.push("An error occured try again.");
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          });
        } else {
          this.loading = false;
          if (!this.formData.bus_cert) {
            this.fileError = "Please upload business certificate.";
          }
          return false;
        }
      });
    },
    editIndividualProfile(formName) {
      this.errors = [];
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          let {
            date_of_birth,
            heard_about_us,
            firstname,
            lastname,
            industry_id
          } = this.formData;
          // if user does not want to set their date of birth, use a defualt value for them,
          let date = date_of_birth == null ? Date("0001", "00", "00") : formatInputDate(date_of_birth);
          // If heard about us is other, get the value from the other field
          heard_about_us = heard_about_us == "Other" ? this.formData.heard_about_us_other !== "" ? this.formData.heard_about_us_other : "Other" : heard_about_us;
          let payload = {
            firstname: firstname,
            lastname: lastname,
            heard_about_us: heard_about_us,
            date_of_birth: date,
            industry_id: industry_id
          };
          store.dispatch("auth/editIndividualProfile", payload).then(response => {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success"
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }).catch(err => {
            var _err$response2;
            this.loading = false;
            if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
              this.errors.push("An error occured try again.");
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    submitIndividualForm(formName) {
      this.errors = [];
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          let {
            date_of_birth,
            heard_about_us,
            firstname,
            lastname,
            industry_id
          } = this.formData;
          // if heard about us is equal to 'Other' then set heard_about_us to the value of this.formData.heard_about_us_other else set it to the value of heard_about_us
          this.formData.heard_about_us = heard_about_us == "Other" ? this.formData.heard_about_us_other !== "" ? this.formData.heard_about_us_other : "Other" : heard_about_us;
          let date = formatInputDate(date_of_birth);
          let payload = {
            firstname: firstname,
            lastname: lastname,
            heard_about_us: heard_about_us,
            date_of_birth: date,
            industry_id: industry_id
          };
          store.dispatch("auth/submitIndividualProfile", payload).then(response => {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success"
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
            togglerCorporateStatus(false);
          }).catch(err => {
            var _err$response3;
            this.loading = false;
            if (((_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
              this.errors.push("An error occured try again.");
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    submitCoperatForm(formName) {
      this.errors = [];
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          let {
            date_of_est,
            heard_about_us,
            name,
            bus_cert,
            industry_id
          } = this.formData;
          let date = formatInputDate(date_of_est);
          //CREATE FORM DATA
          let formData = new FormData();
          formData.append("name", name);
          formData.append("date_of_est", date);
          formData.append("bus_cert", bus_cert);
          formData.append("heard_about_us", heard_about_us);
          formData.append("industry_id", industry_id);
          store.dispatch("auth/submitCoperateProfile", formData).then(response => {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success"
            });
            togglerCorporateStatus(true);
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }).catch(err => {
            var _err$response4;
            this.loading = false;
            if (((_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
              this.errors.push("An error occured try again.");
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          });
        } else {
          this.loading = false;
          if (!this.formData.bus_cert) {
            this.fileError = "Please upload business certificate.";
          }
          return false;
        }
      });
    }
  }
};